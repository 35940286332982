<template>
  <div class="put-question">
    <div class="main">
      <div class="banner-tips">
        <div class="tips">
          <div class="tips-left">
            <img src="../../assets/img/xwTeacher/xw-logo.png" />
          </div>
          <div class="tips-right">
            <div class="tips-name">咨询老师</div>
            <div class="tips-text">
              请描述你目前遇到的问题或困难，我将竭诚为你解答，并保证你的隐私安全
            </div>
          </div>
        </div>
      </div>
      <div class="cell">
        <div class="cell__bd">
          <div class="textarea">
            <el-input
              type="textarea"
              :rows="8"
              placeholder="请详细描述你的问题"
              v-model.trim="question"
              resize="none"
              maxlength="500"
            >
            </el-input>
            <div class="textarea__tip">
              <div class="textarea__counter">
                <span class="">{{ question.length }}/500</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cell__ft"></div>
      </div>
      <div class="info-item">
        <span class="info-item-name">学历：</span>
        <el-select v-model.trim="info.educationValue" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="info-item">
        <span class="info-item-name">高校：</span>
        <el-input
          v-model.trim="info.schoolValue"
          placeholder="请输入学校名称"
        ></el-input>
        <span class="must">*</span>
      </div>
      <div class="info-item">
        <span class="info-item-name">专业：</span>
        <el-input
          v-model.trim="info.majorValue"
          placeholder="请输入专业名称"
        ></el-input>
      </div>
      <div class="info-item">
        <span class="info-item-name">导师：</span>
        <el-input
          v-model.trim="info.teacherValue"
          placeholder="请输入导师名称"
        ></el-input>
      </div>
      <div class="info-item">
        <span class="info-item-name">题目：</span>
        <el-input
          v-model.trim="info.subjectValue"
          placeholder="请输入题目"
        ></el-input>
        <span class="must">*</span>
      </div>
      <div class="info-item">
        <span class="info-item-name">关键词：</span>
        <el-input
          v-model.trim="info.keywordValue"
          placeholder="请输入关键词（多个关键词用分号隔开）"
        ></el-input>
        <span class="must">*</span>
      </div>
      <div class="bottom-btn">
        <div class="next-btn" @click="clickNextBtn">支付（￥{{ price }}）</div>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="200px"
      center
      :show-close="false"
      @close="closeDialog"
    >
      <vue-qr :logoSrc="imageUrl" :text="codeUrl" :size="200"></vue-qr>
      <div class="payTips">请用微信扫码支付</div>
    </el-dialog>
  </div>
</template>

<script>
import vueQr from "vue-qr";
export default {
  name: "putQuestion",
  components: {
    vueQr,
  },
  data() {
    return {
      price: 0,
      question: "",
      options: [
        {
          value: "本科",
          label: "本科",
        },
        {
          value: "高职",
          label: "高职",
        },
        {
          value: "硕士",
          label: "硕士",
        },
      ],
      info: {
        educationValue: "",
        schoolValue: "",
        majorValue: "",
        teacherValue: "",
        subjectValue: "",
        keywordValue: "",
      },
      billId: "",
      imageUrl: require("../../assets/img/xwTeacher/xw-logo.png"),
      codeUrl: "",
      centerDialogVisible: false,
      interval: null,
      loading: null,
    };
  },
  created() {
    document.title = "问老师";
    this.getPrice();
  },
  methods: {
    clickNextBtn() {
      if (!this.question) {
        this.$message({
          type: "warning",
          message: "问题不能为空",
        });
        return;
      }
      if (
        !this.info.schoolValue ||
        !this.info.subjectValue ||
        !this.info.keywordValue
      ) {
        this.$message({
          type: "warning",
          message: "必填项不能为空",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios
        .post("/api/user/advisory/create", {
          content: this.question,
          extInfo: JSON.stringify(this.info),
          isPublish: true,
        })
        .then((res) => {
          console.log(res);
          this.billId = res.data;
          this.getWxPrePayInfo();
        })
        .catch(() => {
          this.loading.close();
        });
    },
    getWxPrePayInfo() {
      this.axios
        .post("/api/pay/user/wx/prePayInfo", this.billId, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          console.log(res);
          this.codeUrl = res.data.codeUrl;
          this.centerDialogVisible = true;
          this.interval = setInterval(() => {
            this.verifyPay();
          }, 1000);
        })
        .catch(() => {
          this.loading.close();
        });
    },
    verifyPay() {
      this.axios
        .post("/api/pay/user/verify", this.billId, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          this.loading.close();
          if (res.data) {
            clearInterval(this.interval);
            this.centerDialogVisible = false;
            this.$router.replace("/myQuestion");
          }
        })
        .catch(() => {
          this.loading.close();
        });
    },
    closeDialog() {
      clearInterval(this.interval);
    },
    getPrice() {
      this.axios
        .get("/api/common/system/config/advisoryPrice")
        .then((res) => {
          console.log(res);
          this.price = res.data.price;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.put-question ::v-deep .el-dialog__body {
  padding: 0;
}
.put-question ::v-deep .el-dialog__header {
  display: none;
}
.put-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f2f2f2;
  .main {
    background-color: #ffffff;
    width: 768px;
    .banner-tips {
      position: relative;
      .tips {
        display: flex;
        padding: 20px 15px;
        background-color: #fff;
        &::after {
          position: absolute;
          right: 16px;
          bottom: 0;
          left: 16px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          height: 1px;
          background-color: #ebebeb;
          -webkit-transform: scaleY(0.5);
          -ms-transform: scaleY(0.5);
          transform: scaleY(0.5);
          content: "";
        }
        .tips-left {
          flex-shrink: 0;
          width: 44px;
          height: 44px;
          margin-right: 15px;
          img {
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid #f2f2f2;
          }
        }
        .tips-right {
          .tips-name {
            color: #333;
            font-weight: 500;
            line-height: 1.13;
          }
          .tips-text {
            position: relative;
            margin-top: 8px;
            padding: 10px 6px;
            color: hsla(0, 0%, 40%, 0.8);
            line-height: 1.5;
            white-space: normal;
            word-break: break-all;
            background-color: #f4f4f4;
            &::before {
              position: absolute;
              top: 10px;
              left: -4px;
              display: inline-block;
              width: 9px;
              height: 9px;
              background: #f4f4f4;
              border-radius: 2px;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              content: " ";
            }
          }
        }
      }
    }
    .cell {
      padding: 14px;
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        border-top: 1px solid #ebebeb;
        transform: scaleY(0.5);
        transform-origin: 0 100%;
        content: "";
      }
      .cell__bd {
        flex: 1;
        overflow: hidden;
        .textarea {
          position: relative;
          width: 100%;
          .textarea__tip {
            position: relative;
            margin-top: 15px;
            line-height: 13px;
            text-align: right;
            .textarea__counter {
              display: inline-block;
              color: #ccc;
            }
          }
        }
      }
    }
    .info-item {
      display: flex;
      align-items: center;
      padding: 10px 14px;
      &::v-deep .el-input {
        width: 333px;
      }
      .info-item-name {
        flex: 0 0 50px;
        width: 50px;
        font-weight: 500;
      }
      .must {
        color: red;
        font-size: 16px;
        padding: 10px;
        font-weight: 500;
      }
    }
    .bottom-btn {
      padding: 14px 4%;
      .next-btn {
        background-color: #00c792;
        color: #ffffff;
        width: 100%;
        height: 46px;
        border-radius: 23px;
        font-size: 15px;
        text-align: center;
        line-height: 46px;
        cursor: pointer;
      }
    }
  }
  .payTips {
    text-align: center;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 900px) {
  .put-question {
    .main {
      width: 100%;
      .info-item {
        
        &::v-deep .el-input {
          width: 260px;
        }
      }
    }
  }
}
</style>